import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Stack, Typography, FormHelperText } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from '#/components/shared/hook-form/styles/styled-radio-group';

type IProps = {
  name: string;
  question: {
    questionKey: string;
    question: string;
    answers?: { value: string; label: string }[];
  };
  disableOption?: (value: string) => boolean;
};

export default function RHFStyledRadioGroup({
  name,
  question,
  disableOption,
}: IProps) {
  const { control, setValue, watch } = useFormContext();
  const { translate } = useLocales();

  const handleOnChange = (
    questionKey: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setValue(`${questionKey}`, value, { shouldValidate: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error } }) => (
        <StyledRadioGroup
          name={question.questionKey}
          onChange={(event) => handleOnChange(question.questionKey, event)}
          value={watch(question.questionKey)}
          sx={{
            ...(error && {
              '& .MuiFormControlLabel-root': {
                borderColor: 'error.main',
              },
            }),
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle1" fontWeight="normal">
              {question.question}
            </Typography>

            {question.answers && (
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                spacing={2}
              >
                {question?.answers?.map((option) => (
                  <StyledFormControlLabel
                    key={`step-${question.questionKey}-${option.value}`}
                    value={option.value}
                    control={<StyledRadio />}
                    label={String(option.label)}
                    disabled={
                      disableOption ? disableOption(option.value) : false
                    }
                  />
                ))}
              </Stack>
            )}
          </Stack>

          {!!error && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error && String(translate(error?.message))}
            </FormHelperText>
          )}
        </StyledRadioGroup>
      )}
    />
  );
}
